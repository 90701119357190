import * as React from "react"
import { Header } from "../../layout/header"
import { Helmet, } from 'react-helmet-async';

import '../../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../../sites/all/themes/tickets/js/estacas/map.js';
import '../../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../../sites/all/themes/tickets/js/estacas/eventos'
import '../../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../../sites/all/themes/tickets/js/estacas/header_rd';
import CartTimer from "../../components/cart_timer";

import PlantillaBlog from "../../layout/plantilla_blog";
// markup
const Node = () => {
  return (
    <body className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com/" />
        <link rel="preload" href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700&display=swap" rel='stylesheet' type='text/css' />
        <link rel="preload" href="https://fonts.googleapis.com/css?family=Prompt:100,200,300,400,500,600,700|Work+Sans:100,200,300,400,500,600,700&display=swap" rel='stylesheet' type='text/css' />
          <script src={"https://code.jquery.com/jquery-2.2.4.min.js"} integrity={"sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44="} crossOrigin="anonymous"></script>
          <script src={"../../static/drupal/cart_timer.js?version=124"} type="text/javascript" />
          <link type="text/css" rel="stylesheet" href={"../../static/drupal/cart_timer.css?version=124"}/>

      </Helmet>
      <PlantillaBlog />
      <CartTimer/>
    </body>
  )
}

export default Node
