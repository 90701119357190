import $ from 'jquery'; 

const isBrowser = typeof window !== "undefined"

if (isBrowser) {
  

$( document ).ready(function()  {

		

			var campoBoleto = $('#compra-tickets-entityform-edit-form .group-wrapper-form .field-name-field-tipos-de-entrada .form-item-field-tipos-de-entrada-und input[name="field_tipos_de_entrada[und]"]');
			var resumen = $('#edit-field-visitantes .form-item label');
			var tipoDeBoleto = 'entrada';
			var precioGeneralAdult;
			var precioGeneralMenor;
			var precioCampingAdult;
			var precioCampingMenor;
			var precioCampingNocheAdultAdd;
			var precioCampingNocheMenorAdd;
			var boletoGeneralAdult = 0;
			var boletoGeneralMenor = 0;
			var boletosCampingAdult = 0;
			var boletosCampingMenor = 0;
			var boletoCampingNoche = 0;
			var boletoCampingNocheAdd = 0;
			var evaluacionCamAdulto =0;
			var nextTotalCamAdult=0;
			var evaluacionCamMenor =0;
			var nextTotalCamMenor =0;
			var evaluacionNoche =0;
			var nextTotalNoche =0;
			var total = 0;
			var product;
			
		/*	fetch('https://silvio.estacas.dev.m22.mx/api/v1/views/productos_e_commerce?display_id=services_1&format_output=0')
  .then(response => response.json())
  .then(data => {
	  product=Object.values(data);
	  console.log(data)
  });*/
	/*if (Drupal.settings.tk != undefined) {
				var product = Object.values(Drupal.settings.tk.products_data);
			}*/
			
			$('.has-tip.tip-top').text('?');

			//$('<p class="paragraph-sb tarifas">*Menores de 90 cm entran gratis. Para más información sobre precios puedes visitar nuestra sección de <a href="/tarifas" class="btn-tex-mob-sb">Precios</a></p>').appendTo('.block-entityform-block.block-entityform-block-compra-tickets form >div')
			setTimeout(() => {
				
			/*
				código sku: "435-Campinggeneral"
enlace de edición: ""
estado: "Activo"
precio: "$600.00"
tipo: "Entrada 2019"
título: "<a href=\"/admin/commerce/products/435\">Camping general</a>"

			*/
			console.log(product)
			if (product != undefined) {
				for (var i = 0; i <= product.length-1; i++) {
					console.log('productos');
					console.log(product[i]['código sku']);
					//precio para entrada General de adulto
					if (product[i].tipo.includes('Entrada') && product[i]['código sku'].includes('general')) {
						precioGeneralAdult = product[i]['precio'];
						console.log(precioGeneralAdult)
					}
					//precio para entrada General de menor
					else if (product[i].tipo.includes('Entrada') && product[i]['código sku'].includes('menor')) {
						precioGeneralMenor = product[i]['precio'];
					}
					//precio para entrada Camping de adulto y noche adicional (con descuento)
					else if (product[i]['entry_type'] == 'camping' && product[i]['ticket_type'] == 'general') {
						precioCampingAdult = product[i]['price'];
						precioCampingNocheAdultAdd = product[i]['aditional_unit'];
					}
					//precio para entrada Camping de menor y noche adicional (con descuento)
					else if (product[i]['entry_type'] == 'camping' && product[i]['ticket_type'] == 'menor') {
						precioCampingMenor = product[i]['price'];
						precioCampingNocheMenorAdd = product[i]['aditional_unit'];
					}
					// else if(product[i]['entry_type'] == null && product[i]['ticket_type'] == null){
					// 	console.log(product[i]['price']);
					// 	console.log(product[i]['aditional_unit']);
					// }
				}

				//identifica si se cambia el tipo de boleto
				precioTotal(tipoDeBoleto);

				campoBoleto.on('click', function(){
					$('#edit-field-visitantes input').val('$0');
					tipoDeBoleto = $(this).val();
					precioTotal(tipoDeBoleto);				
				});

				function precioTotal(tipoDeBoleto) {
					if (tipoDeBoleto == 'entrada') {//si se selecciona entrada en el tipo de boleto, entra a este condicional
						$('#compra-tickets-entityform-edit-form > div').removeClass('camping').addClass('general');

						$('.required-fields.group-entradas.field-group-div').addClass('visible');
						$('.required-fields.group-camping.field-group-div').removeClass('visible');
						var value=0;
						var value1=0;
						var personas =0;
						var pga = parseFloat(precioGeneralAdult.replace('$',''));
						var pgm = parseFloat(precioGeneralMenor.replace('$',''));
						resumen.text('0 visitantes'); //se refresca el resumen para empezar desde 0 la compra
						$('#edit-field-entrada-general button.less').on('click',function (event) {
							console.log('clic menor');
							if (value>0) {
								value =value-1;
								$('#edit-field-entrada-general-und-0-value').val(value);
								var inputboletoAdulto = $('#edit-field-entrada-general-und-0-value').val();
							var inputboletoMenor = $('#edit-field-entrada-menor input').val();
							total = (inputboletoAdulto * pga) + (inputboletoMenor * pgm);
							console.log(pga)
							personas = value+value1;
							$('#edit-field-visitantes-und-0-value').val('$'+total);
							}else{
							$('#edit-field-entrada-general-und-0-value').val(0);
							}
							console.log(personas)
							$('#visitantes').empty()
							$('#visitantes').append(personas+' visitantes:')

						})
						$('#edit-field-entrada-general button.more').on('click',function (event) {
							console.log('clic mayor');
							value=value+1
							$('#edit-field-entrada-general-und-0-value').val(value);
							var inputboletoAdulto = $('#edit-field-entrada-general-und-0-value').val();
							var inputboletoMenor = $('#edit-field-entrada-menor input').val();
							total = (inputboletoAdulto * pga) + (inputboletoMenor * pgm);
							console.log(pga)
							personas = value+value1;
							$('#edit-field-visitantes-und-0-value').val('$'+total);
							$('#visitantes').empty()
							$('#visitantes').append(personas+' visitantes:')
						})

						$('#edit-field-entrada-menor button.less').on('click',function (event) {
							console.log('clic menor');
							if (value1>0) {
								value1 =value1-1;
								$('#edit-field-entrada-menor-und-0-value').val(value1);
								var inputboletoAdulto = $('#edit-field-entrada-general-und-0-value').val();
							var inputboletoMenor = $('#edit-field-entrada-menor input').val();
							total = (inputboletoAdulto * pga) + (inputboletoMenor * pgm);
							console.log(pga)
							personas = value+value1;
							$('#edit-field-visitantes-und-0-value').val('$'+total);
							}else{
							$('#edit-field-entrada-menor-und-0-value').val(0);
							}
							$('#visitantes').empty()
							$('#visitantes').append(personas+' visitantes:')

						})
						$('#edit-field-entrada-menor button.more').on('click',function (event) {
							console.log('clic mayor');
							value1=value1+1
							
							$('#edit-field-entrada-menor-und-0-value').val(value1);
							var inputboletoAdulto = $('#edit-field-entrada-general-und-0-value').val();
							var inputboletoMenor = $('#edit-field-entrada-menor input').val();
							total = (inputboletoAdulto * pga) + (inputboletoMenor * pgm);
							console.log(pga)
							personas = value+value1;
							$('#edit-field-visitantes-und-0-value').val('$'+total);
							$('#visitantes').empty()
							$('#visitantes').append(personas+' visitantes:')

						})
						/*$('#edit-field-entrada-general button.less, #edit-field-entrada-general button.more').on('click', function(event) { //click acualquiera de los dos botones de entrada General
							
							var button = $(this);
							validteTotalPrice(button);
							var inputboletoMenor = $('#edit-field-entrada-menor input').val();
							var personas;

							boletoGeneralAdult = $(this).siblings('input').val();
							total = (boletoGeneralAdult * precioGeneralAdult) + (inputboletoMenor * precioGeneralMenor);
							$('#edit-field-visitantes input').val('$'+total);
							console.log(total)
							personas = parseInt(inputboletoMenor) + parseInt(boletoGeneralAdult);
							if (personas > 1) {
								resumen.text(personas +' visitantes');
							}else {
								resumen.text(personas +' visitante');
							}
						});
						$('#edit-field-entrada-menor button.less, #edit-field-entrada-menor button.more').on('click', function(event) { //click acualquiera de los dos botones de entrada Menor
							var button = $(this);
							validteTotalPrice(button);
							var inputboletoAdulto = $('#edit-field-entrada-general input').val();
							var personas;
							boletoGeneralMenor = $(this).siblings('input').val();
							total = (inputboletoAdulto * precioGeneralAdult) + (boletoGeneralMenor * precioGeneralMenor);
							$('#edit-field-visitantes input').val('$'+total);
							
							personas = parseInt(inputboletoAdulto) + parseInt(boletoGeneralMenor);
							if (personas > 1) {
								resumen.text(personas +' visitantes');
							}else {
								resumen.text(personas +' visitante');
							}
						});*/
		            }
		            /*if (tipoDeBoleto == 'camping') { //si se selecciona camping en el tipo de boleto, entra a este condicional
		            	$('#compra-tickets-entityform-edit-form > div').removeClass('general').addClass('camping');
		            	$('.required-fields.group-camping.field-group-div').addClass('visible');
						$('.required-fields.group-entradas.field-group-div').removeClass('visible');

		            	resumen.text('0 visitantes y 1 noche');  //se refresca el resumen para empezar desde 0 la compra y 1 noche, por ser el valor minimo
		            	$('#edit-field-camping-noches-adicionales button.less, #edit-field-camping-noches-adicionales button.more').on('click', function(event) {//click a cualquiera de los dos botones de camping Noches
		            		var button = $(this);
		            		validteTotalPrice(button);
							var inputboletoCampingAdult = $('#edit-field-camping-general input').val();
							var inputboletoCampingMenor = $('#edit-field-camping-menor input').val();
							var personas;
							var noches;

							boletoCampingNoche = $(this).siblings('input').val();
							boletoCampingNocheAdd = boletoCampingNoche -1;

							personas = parseInt(inputboletoCampingAdult) + parseInt(inputboletoCampingMenor);
							noches = parseInt(boletoCampingNoche);

							total = (precioCampingAdult * inputboletoCampingAdult) + (precioCampingMenor * inputboletoCampingMenor) + (precioCampingNocheAdultAdd * inputboletoCampingAdult * boletoCampingNocheAdd) + (precioCampingNocheMenorAdd * inputboletoCampingMenor * boletoCampingNocheAdd);
							if (noches < 1) {
								$('#edit-field-visitantes input').val('$0');
							}else {
								$('#edit-field-visitantes input').val('$'+total);
							}

							if (personas > 1 && noches >1 ) {
								resumen.text(personas +' visitantes y '+ noches + ' noches');
							}else if (personas < 2 && noches >1) {
								resumen.text(personas +' visitante y '+ noches + ' noches');
							}else if (personas > 1 && noches < 2) {
								resumen.text(personas +' visitantes y '+ noches + ' noche');
							} else if (personas < 2 && noches < 2) {
								resumen.text(personas +' visitante y '+ noches + ' noche');
							}
						});
		            	$('#edit-field-camping-general button.less, #edit-field-camping-general button.more').on('click', function(event) {//click acualquiera de los dos botones de camping General
		            		var button = $(this);
		            		validteTotalPrice(button);
							var inputboletoCampingNoches = $('#edit-field-camping-noches-adicionales input').val();
							var nochesCampingconDesc = inputboletoCampingNoches -1;
							var inputboletoCampingMenor = $('#edit-field-camping-menor input').val();
							var personas;
							var noches;
							boletosCampingAdult = $(this).siblings('input').val();

							total = (precioCampingAdult * boletosCampingAdult) + (precioCampingMenor * inputboletoCampingMenor) + (precioCampingNocheAdultAdd * boletosCampingAdult * nochesCampingconDesc) + (precioCampingNocheMenorAdd * inputboletoCampingMenor * nochesCampingconDesc)  ;

							personas = parseInt(boletosCampingAdult) + parseInt(inputboletoCampingMenor);
							noches = parseInt(inputboletoCampingNoches);
							if (noches < 1) {
								$('#edit-field-visitantes input').val('$0');
							}else {
								$('#edit-field-visitantes input').val('$'+total);
							}
							if (personas > 1 && noches >1 ) {
								resumen.text(personas +' visitantes y '+ noches + ' noches');
							}else if (personas < 2 && noches >1) {
								resumen.text(personas +' visitante y '+ noches + ' noches');
							}else if (personas > 1 && noches < 2) {
								resumen.text(personas +' visitantes y '+ noches + ' noche');
							} else if (personas < 2 && noches < 2) {
								resumen.text(personas +' visitante y '+ noches + ' noche');
							}
						});
						$('#edit-field-camping-menor button.less, #edit-field-camping-menor button.more').on('click', function(event) {//click acualquiera de los dos botones de camping Menor
							var button = $(this);
							validteTotalPrice(button);
							var inputboletoCampingNoches = $('#edit-field-camping-noches-adicionales input').val();
							var nochesCampingconDesc = inputboletoCampingNoches -1;
							var inputBoletoCampingAdult = $('#edit-field-camping-general input').val();
							var personas;
							var noches;

							boletosCampingMenor = $(this).siblings('input').val();

							personas = parseInt(inputBoletoCampingAdult) + parseInt(boletosCampingMenor);

							noches = parseInt(inputboletoCampingNoches);
							total = (precioCampingAdult * inputBoletoCampingAdult) + (precioCampingMenor * boletosCampingMenor) + (precioCampingNocheAdultAdd * inputBoletoCampingAdult * nochesCampingconDesc) + (precioCampingNocheMenorAdd * boletosCampingMenor * nochesCampingconDesc);
							if (noches < 1) {
								$('#edit-field-visitantes input').val('$0');
							}else {
								$('#edit-field-visitantes input').val('$'+total);
							}
							if (personas > 1 && noches >1 ) {
								resumen.text(personas +' visitantes y '+ noches + ' noches');
							}else if (personas < 2 && noches >1) {
								resumen.text(personas +' visitante y '+ noches + ' noches');
							}else if (personas > 1 && noches < 2) {
								resumen.text(personas +' visitantes y '+ noches + ' noche');
							} else if (personas < 2 && noches < 2) {
								resumen.text(personas +' visitante y '+ noches + ' noche');
							}
						});
		            }*/
				}

				function validteTotalPrice(){
					/* Precios obtenidos desde commerce por medio de druppal.settings
						-  precioGeneralAdult;
						-  precioGeneralMenor
						-  precioCampingAdult
						-  precioCampingMenor
						-  precioCampingNocheAdultAdd
						-  precioCampingNocheMenorAdd

						Se calculan los montos siguientes en caso de que quede superen los 11500 pesos, se desactiva el botón de mas

						NOTA: solo se hace para camping ya que para entradas generales no supuera los 11500 pesos.
					*/
					var CamAdul = parseInt($('#edit-field-camping-general input').val());
					var CamMenor = parseInt($('#edit-field-camping-menor input').val());
					var noches = parseInt($('#edit-field-camping-noches-adicionales input').val());
					var nocheAdd = noches-1;

					evaluacionCamAdulto =CamAdul+1;
					nextTotalCamAdult = (precioCampingAdult * evaluacionCamAdulto) + (precioCampingMenor * CamMenor) + (precioCampingNocheAdultAdd * evaluacionCamAdulto * nocheAdd) + (precioCampingNocheMenorAdd * CamMenor * nocheAdd);

					evaluacionCamMenor =CamMenor+1;
					nextTotalCamMenor = (precioCampingAdult * CamAdul) + (precioCampingMenor * evaluacionCamMenor) + (precioCampingNocheAdultAdd * CamAdul * nocheAdd) + (precioCampingNocheMenorAdd * evaluacionCamMenor * nocheAdd);

					evaluacionNoche =noches;
					nextTotalNoche = (precioCampingAdult * CamAdul) + (precioCampingMenor * CamMenor) + (precioCampingNocheAdultAdd * CamAdul * evaluacionNoche) + (precioCampingNocheMenorAdd * CamMenor * evaluacionNoche);

					if ( nextTotalCamAdult > 11500 ) {	
						$('#edit-field-camping-general button.more').prop('disabled', true);
					}else {
						$('#edit-field-camping-general button.more').removeAttr('disabled');
					}
					if ( nextTotalCamMenor > 11500 ) {
						$('#edit-field-camping-menor button.more').prop('disabled', true);
					}else {
						$('#edit-field-camping-menor button.more').removeAttr('disabled');
					}
					if ( nextTotalNoche > 11500 ) {	
						$('#edit-field-camping-noches-adicionales button.more').prop('disabled', true);
					}else {
						$('#edit-field-camping-noches-adicionales button.more').removeAttr('disabled');
					}

				}

			}
		}, 1000);

});
}